<template>
  <div id="app-base-db" class="app-base-db">
      <div class="dlaheader">
        <div class="dlatitle">{{dlaname}}</div>
        <div class="black-close" @click="mindla()"></div>
      </div>
      <div class="dlabody" v-html="dlacontent"></div>
  </div>
</template>
<script>
import { ipcApiRoute , requestHttpPost , requestHttpGet} from '@/api/main'
export default {
  data() {
    return {
      dlaname:"用户协议",
      dlatitle : "userxy",
      dlacontent:""
    };
  },
  mounted() {
    // this.dlacontent = "用户协议"
    this.initdata()
  },
  methods: {
    async initdata(){
      let data = await requestHttpGet('https://ucenter.binliyoujia.com/index.php?ctl=Login&met=protocol&typ=json', {})
      this.dlacontent = data.data.reg_protocol
    },
    mindla(){ 
      this.$ipc.invoke(ipcApiRoute.closeWinByName, this.dlatitle).then(result => {
          console.log(result)
      })
    },
  }
};
</script>
<style lang="less" scoped>
.app-base-db{
  padding: 16px;
  background-color: #fff;
}
.doc-text{
  color: #ED2616;
}
.dec-p{
  display: flex;
  width: 260px;
  margin: auto;
  margin-top: 16px;
  cursor: pointer;
}
.dec-plant{
  width: 100%;
}
.dec-text{
  color: #999;
  font-size: 12px;
}
.login-button {
  width: 260px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(90deg, #FE5D42 0%, #ED2616 100%);
  border: none;
  color: #fff;
  margin-top: 21px;
  cursor: pointer;
}
.login-checkbox-dec {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-top: 2px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
  position: relative;
}

.login-checkbox-dec::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
}


.login-checkbox-dec:checked::before {
  content: "\2714";
  display: block;
  text-align: center;
  background-color: #ED2616;
  color: #fff;
  position: absolute;
  top: -1px;
  width: 12px;
  height: 12px;
  border: 1px solid #ED2616;
  font-size: 10px;
  border-radius: 2px;
  line-height: 12px;
}
.login-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
  position: relative;

}

.login-checkbox::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  background: #fff;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px #D8D8D8 solid;
  cursor: pointer;
}


.login-checkbox:checked::before {
  content: "\2714";
  display: block;
  text-align: center;
  background-color: #ED2616;
  color: #fff;
  position: absolute;
  top: -1px;
  width: 16px;
  height: 16px;
  border: 1px solid #ED2616;
  font-size: 12px;
  border-radius: 2px;
  line-height: 12px;
}

.edit-button {
  display: flex;
  justify-content: space-between;
  color: #999;
  width: 260px;
  margin: auto;
  margin-top: 6px;
  font-family: PingFang SC;
  font-size: 12px;
  cursor: pointer;
}

.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  width: 260px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #999;
}

.login-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.login-input::placeholder {
  color: #D8D8D8;
}

.mtop16 {
  margin-top: 16px;
}

.header-body {
  margin-top: 45px;
}

.app-base-db {
  border-radius: 10px;
}

.login-plant {
  width: 400px;
  height: 355px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  margin: auto;
}

.login-header {
  width: 100%;
  height: 106px;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(105deg, #FE5D42 0%, #EB5958 51%, #FCAC8C 100%, #FF6F55 100%);
  display: flex;
  position: relative;
}

.mtp200 {
  margin-top: 200px;
}

.login-header-image {
  position: absolute;
  top: 75px;
  width: 100%;
  height: 60px;

}

.login-header-image .imgdiv {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid rgba(236, 47, 34, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(99, 23, 23, 0.3);
  background: #fff;
  background-image: url('~@/assets/headerd.png');
  background-size: 100%;
  margin: auto;
}

.action {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.min {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-image: url('~@/assets/minus.png');
  background-size: 100%;
}

.close {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-image: url('~@/assets/close.png');
  background-size: 100%;
}
.black-close{
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 3px;
}

.login-logo {
  background-image: url('~@/assets/logo.png');
  background-size: 100%;
  position: absolute;
  top: 16px;
  left: 16px;
  width: 70px;
  height: 20px;
  -webkit-app-region: drag;
  -webkit-user-select: none;
}
/deep/ .ant-message-info .anticon, .ant-message-loading .anticon{
  color: #ED2616 !important;
}
.dlaheader{
  display: flex;
  justify-content: space-between;
}
.dlatitle{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #222222;
  font-weight: 400;
}
/deep/ .ant-modal-body{
  padding: 16px;
}
.dlabody{
  margin-top: 16px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dlabody::-webkit-scrollbar{
  background-color: #fff;
  width: 3px;
}
.dlabody::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
</style>
